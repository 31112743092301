import React from "react";
import { Marker, Popup } from "react-leaflet";
import { calculateDistance } from "../../utils/distance";

const FarmMarker = ({ farm, icon, selectedSchool }) => {
  return (
    <Marker position={[farm.latitude, farm.longitude]} icon={icon}>
      {/* In FarmMarker.jsx */}
      <Popup>
        <div>
          <strong>{farm.company_name}</strong>
          <br />
          {farm.registered_office_address}, {farm.postcode}
          <br />
          Council: {farm.council}
          <br />
          Produce: {farm.food_Item || "Unknown"}
          {selectedSchool && (
            <>
              <br />
              Distance from {selectedSchool.SchoolName}:{" "}
              {calculateDistance(
                selectedSchool.Latitude,
                selectedSchool.Longitude,
                farm.latitude,
                farm.longitude
              ).toFixed(1)}{" "}
              km
            </>
          )}
        </div>
      </Popup>
    </Marker>
  );
};

export default FarmMarker;
