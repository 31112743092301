import React from "react";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MapViewUpdater from "./MapViewUpdater";
import SchoolMarker from "./SchoolMarker";
import FarmMarker from "./FarmMarker";
import "./styles.css";

// Fix Leaflet's default icon issue
delete L.Icon.Default.prototype._getIconUrl;

// Create custom icon function
const createIcon = (iconUrl) => {
  return new L.Icon({
    iconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });
};

const MapComponent = ({ 
  mapCenter, 
  mapZoom, 
  selectedSchool, 
  filteredSchools, 
  filteredFarms, 
  showSchools,
  distanceFilter,
  schoolIconSvg,
  farmIconSvg
}) => {
  // Create icons using SVG files
  const schoolIcon = createIcon(schoolIconSvg);
  const farmIcon = createIcon(farmIconSvg);

  return (
    <div className="map-container">
      <MapContainer 
        center={mapCenter} 
        zoom={mapZoom} 
        scrollWheelZoom={true}
        zoomControl={true} // Explicitly enable zoom controls
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        
        {/* Update map view when center changes */}
        <MapViewUpdater center={mapCenter} zoom={mapZoom} />
        
        {/* Render schools */}
        {filteredSchools.map((school, index) => (
          <SchoolMarker 
            key={`school-${index}`}
            school={school}
            icon={schoolIcon}
          />
        ))}
        
        {/* Render farms */}
        {filteredFarms.map((farm, index) => (
          <FarmMarker 
            key={`farm-${index}`}
            farm={farm}
            icon={farmIcon}
            selectedSchool={selectedSchool}
          />
        ))}
        
        {/* Draw a radius circle if school is selected */}
        {selectedSchool && showSchools && (
          <Circle 
            center={[selectedSchool.Latitude, selectedSchool.Longitude]}
            radius={distanceFilter * 1000} // Convert km to meters
            pathOptions={{ fillColor: 'blue', fillOpacity: 0.1, color: 'blue', weight: 1 }}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default MapComponent;