import { useState, useEffect } from 'react';
import { isWithinUK } from '../utils/ukBoundaries';

export const useDataFetching = () => {
  const [farms, setFarms] = useState([]);
  const [schools, setSchools] = useState([]);
  const [councils, setCouncils] = useState([]);
  const [farmProduceList, setFarmProduceList] = useState([]);
  const [schoolTypes, setSchoolTypes] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Paths to JSON files
      const farmsPath = "/data/farms.json";
      const schoolsPath = "/data/schools.json";
      
      try {
        // Fetch farms data with better error handling
        console.log("Fetching farms data from:", farmsPath);
        const farmsResponse = await fetch(farmsPath);
        
        if (!farmsResponse.ok) {
          throw new Error(`Failed to fetch farms data: ${farmsResponse.status} ${farmsResponse.statusText}`);
        }
        
        // Get raw text first to inspect
        const farmsRawText = await farmsResponse.text();
        
        // Log a sample of the response to debug
        console.log("Farms raw response (first 100 chars):", farmsRawText.substring(0, 100));
        
        // Try to parse JSON
        let farmsData;
        try {
          farmsData = JSON.parse(farmsRawText);
        } catch (jsonError) {
          console.error("JSON parsing error for farms data:", jsonError);
          throw new Error(`Invalid JSON in farms data: ${jsonError.message}`);
        }
        
        // Process farms data
        const processedFarms = farmsData.map(farm => ({
          ...farm,
          company_name: farm.company_name || "Unknown Farm",
          town: farm.town || "Unknown",
          council: farm.council || "Unknown",
          food_Item: farm.food_Item || "Unknown",
          registered_office_address: farm.registered_office_address || "",
          postcode: farm.postcode || "",
          latitude: parseFloat(farm.latitude),
          longitude: parseFloat(farm.longitude)
        }))
        .filter(farm => 
          !isNaN(farm.latitude) && 
          !isNaN(farm.longitude) && 
          isWithinUK(farm.latitude, farm.longitude)
        );
        
        setFarms(processedFarms);
        
        // Extract unique farm produce types
        const allProduce = [...new Set(processedFarms.flatMap(farm => {
          return farm.food_Item ? farm.food_Item.split(';').map(item => item.trim()) : [];
        }))].filter(Boolean).sort();

        setFarmProduceList(allProduce);

        // Fetch schools data with better error handling
        console.log("Fetching schools data from:", schoolsPath);
        const schoolsResponse = await fetch(schoolsPath);
        
        if (!schoolsResponse.ok) {
          throw new Error(`Failed to fetch schools data: ${schoolsResponse.status} ${schoolsResponse.statusText}`);
        }
        
        // Get raw text first to inspect
        const schoolsRawText = await schoolsResponse.text();
        
        // Log a sample of the response to debug
        console.log("Schools raw response (first 100 chars):", schoolsRawText.substring(0, 100));
        
        // Try to parse JSON
        let schoolsData;
        try {
          schoolsData = JSON.parse(schoolsRawText);
        } catch (jsonError) {
          console.error("JSON parsing error for schools data:", jsonError);
          throw new Error(`Invalid JSON in schools data: ${jsonError.message}`);
        }
        
        // Process schools data
        const validSchools = schoolsData
          .map((school) => ({
            ...school,
            SchoolName: school.SchoolName || "Unknown School",
            Address: school.Address || "",
            Postcode: school.Postcode || "",
            Total: school.Total || 0,
            SchoolType: school.SchoolType || "Unknown",
            SchoolCategory: school.SchoolCategory || "public",
            LocalAuthority: school.LocalAuthority || "Unknown",
            Latitude: parseFloat(school.Latitude),
            Longitude: parseFloat(school.Longitude),
          }))
          .filter((school) => 
            !isNaN(school.Latitude) && 
            !isNaN(school.Longitude) && 
            isWithinUK(school.Latitude, school.Longitude)
          );

        // Sort schools alphabetically
        const sortedSchools = [...validSchools].sort((a, b) => 
          a.SchoolName.localeCompare(b.SchoolName)
        );
        
        setSchools(sortedSchools);
        
        // Extract unique values for dropdowns
        setCouncils([...new Set(validSchools.map((school) => school.LocalAuthority))].sort());
        setSchoolTypes([...new Set(validSchools.map((school) => school.SchoolType))].sort());
        setSchoolCategories([...new Set(validSchools.map((school) => school.SchoolCategory))].sort());
        
        setLoading(false);
      } catch (err) {
        console.error("Data fetching error:", err);
        setError(err.message || "Failed to load data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    farms,
    schools,
    councils,
    farmProduceList,
    schoolTypes,
    schoolCategories,
    loading,
    error
  };
};