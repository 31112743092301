import { useEffect } from "react";
import { useMap } from "react-leaflet";

// Component to recenter map when a school is selected
const MapViewUpdater = ({ center, zoom }) => {
  const map = useMap();
  
  useEffect(() => {
    if (center) {
      map.setView(center, zoom || map.getZoom());
    }
  }, [center, zoom, map]);
  
  return null;
};

export default MapViewUpdater;