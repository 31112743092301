import React from "react";
import "./styles.css";

const Legend = ({ schoolIconSvg, farmIconSvg }) => {
  return (
    <div className="map-legend">
      <div className="legend-item">
        <div 
          className="legend-icon" 
          style={{ backgroundImage: `url(${schoolIconSvg})` }}
        ></div>
        <span>Schools</span>
      </div>
      <div className="legend-item">
        <div 
          className="legend-icon" 
          style={{ backgroundImage: `url(${farmIconSvg})` }}
        ></div>
        <span>Farms</span>
      </div>
    </div>
  );
};

export default Legend;