import React, { useState, useEffect } from "react";
import "./SchoolFarmMap.css";
import { useDataFetching } from "./hooks/useDataFetching";
import { calculateDistance } from "./utils/distance";
import FilterPanel from "./components/FilterPanel";
import MapComponent from "./components/Map";
import Legend from "./components/Legend";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

// Import your SVG images - update these paths to match your actual file locations
import schoolIconSvg from "./assets/images/school-icon.svg";
import farmIconSvg from "./assets/images/farm-icon.svg";

import filterIcon from "./assets/images/filter-icon.png";
// Default filter state
const defaultFilterState = {
  council: "all",
  schoolType: "all",
  schoolCategory: "all",
  farmProduce: "all",
  distance: 30,
  selectedSchoolId: null,
  showSchools: true,
  showFarms: true,
};

// Modified the component definition to include auth prop
const SchoolFarmMap = ({ auth }) => {
  const history = useHistory();
  // State for mobile filter panel visibility
  const [filterVisible, setFilterVisible] = useState(false);

  // Fetch data using custom hook
  const {
    farms,
    schools,
    councils,
    farmProduceList,
    schoolTypes,
    schoolCategories,
    loading,
    error,
  } = useDataFetching();

  // State for filters and map view
  const [filters, setFilters] = useState({ ...defaultFilterState });
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [mapCenter, setMapCenter] = useState([56.4907, -4.2026]);
  const [mapZoom, setMapZoom] = useState(7);

  // When a school is selected, update map center and filters
  // MOVED THIS useEffect ABOVE the auth check
  useEffect(() => {
    if (selectedSchool) {
      setMapCenter([selectedSchool.Latitude, selectedSchool.Longitude]);
      setMapZoom(12);
      setFilters((prev) => ({
        ...prev,
        selectedSchoolId: selectedSchool.id,
        council: "all", // Set council filter to "all" when a school is selected
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        selectedSchoolId: null,
      }));
    }
  }, [selectedSchool]);

  // Add authentication check AFTER all hooks are called
  if (!auth.uid) return <Redirect to="/login" />;

  // Reset all filters to default values
  const handleResetFilters = () => {
    setFilters({ ...defaultFilterState });
    setSelectedSchool(null);
    setMapCenter([56.4907, -4.2026]);
    setMapZoom(7);
  };

  // Toggle schools visibility
  const handleToggleSchools = (event) => {
    setFilters({
      ...filters,
      showSchools: event.target.checked,
    });
  };

  // Toggle farms visibility
  const handleToggleFarms = (event) => {
    setFilters({
      ...filters,
      showFarms: event.target.checked,
    });
  };

  // Handle school selection
  const handleSchoolSelect = (e) => {
    const schoolId = e.target.value;
    if (schoolId === "all") {
      setSelectedSchool(null);
      setMapCenter([56.4907, -4.2026]);
      setMapZoom(7);
    } else {
      const school = schools.find(
        (s) => s.id === schoolId || s.SchoolName === schoolId
      );
      setSelectedSchool(school);
    }
  };

  // Go back to previous page
  const handleGoBack = () => {
    // For testing without router, you can just log it
    // console.log("Back button clicked");
    // Uncomment this when router is available
    history.goBack();
  };

  // Toggle filter panel visibility on mobile
  const toggleFilterPanel = () => {
    setFilterVisible(!filterVisible);
  };

  // Apply filters to schools
  const filteredSchools = schools.filter((school) => {
    // First check if schools should be shown at all
    if (!filters.showSchools) {
      return false;
    }

    // If a school is selected, only show that specific school
    if (selectedSchool) {
      return school.SchoolName === selectedSchool.SchoolName;
    }

    // If no school selected, apply the other filters
    // Council filter
    if (
      filters.council !== "all" &&
      school.LocalAuthority !== filters.council
    ) {
      return false;
    }

    // School type filter
    if (
      filters.schoolType !== "all" &&
      school.SchoolType !== filters.schoolType
    ) {
      return false;
    }

    // School category filter
    if (
      filters.schoolCategory !== "all" &&
      school.SchoolCategory !== filters.schoolCategory
    ) {
      return false;
    }

    return true;
  });

  // Apply filters to farms
  const filteredFarms = farms.filter((farm) => {
    // First check if farms should be shown at all
    if (!filters.showFarms) {
      return false;
    }

    // Council filter
    if (filters.council !== "all" && farm.council !== filters.council) {
      return false;
    }

    // Farm produce filter
    if (filters.farmProduce !== "all") {
      // Check if the selected produce is in the food_Item (which might be a semicolon-separated list)
      const matchesFoodItem =
        farm.food_Item &&
        farm.food_Item
          .split(";")
          .map((item) => item.trim())
          .includes(filters.farmProduce);

      // If it doesn't match, filter it out
      if (!matchesFoodItem) {
        return false;
      }
    }

    // Distance filter if a school is selected
    if (selectedSchool) {
      const distance = calculateDistance(
        selectedSchool.Latitude,
        selectedSchool.Longitude,
        farm.latitude,
        farm.longitude
      );

      if (distance > filters.distance) {
        return false;
      }
    }

    return true;
  });

  // Show loading state
  if (loading) {
    return <div className="loading">Loading map data...</div>;
  }

  // Show error state
  if (error) {
    return <div className="error">Error loading map data: {error.message}</div>;
  }

  return (
    <div className="school-farm-app">
      <div className="app-container">
        {/* Mobile controls with icons */}
        <div className="mobile-controls">
          <button className="back-btn" onClick={handleGoBack}>
            &#8592;
          </button>
          <button className="filter-toggle-btn" onClick={toggleFilterPanel}>
            <img src={filterIcon} alt="Filter" className="filter-icon" />
          </button>
        </div>

        {/* Filter panel with conditional class for mobile visibility */}
        <div
          className={`filter-panel ${filterVisible ? "filter-visible" : ""}`}
        >
          <div className="filter-header">
            <button className="back-btn desktop-back" onClick={handleGoBack}>
              <span className="back-arrow">&#8592;</span>
            </button>
          </div>

          <FilterPanel
            filters={filters}
            setFilters={setFilters}
            councils={councils}
            schoolTypes={schoolTypes}
            schoolCategories={schoolCategories}
            farmProduceList={farmProduceList}
            schools={schools}
            selectedSchool={selectedSchool}
            handleSchoolSelect={handleSchoolSelect}
            handleToggleSchools={handleToggleSchools}
            handleToggleFarms={handleToggleFarms}
          />

          {/* Reset filter button moved to bottom */}
          <div className="filter-reset">
            <button className="reset-button" onClick={handleResetFilters}>
              Reset All Filters
            </button>
          </div>

          {/* Close button for mobile */}
          <button
            className="close-filters-btn"
            onClick={() => setFilterVisible(false)}
          >
            Close Filters
          </button>
        </div>

        <MapComponent
          mapCenter={mapCenter}
          mapZoom={mapZoom}
          selectedSchool={selectedSchool}
          filteredSchools={filteredSchools}
          filteredFarms={filteredFarms}
          showSchools={filters.showSchools}
          distanceFilter={filters.distance}
          schoolIconSvg={schoolIconSvg}
          farmIconSvg={farmIconSvg}
        />
        <Legend schoolIconSvg={schoolIconSvg} farmIconSvg={farmIconSvg} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(SchoolFarmMap);
