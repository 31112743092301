import React from "react";
import "./styles.css";

const FilterGroup = ({ label, value, onChange, options, children }) => {
  // If children are provided, render them directly
  if (children) {
    return (
      <div className="filter-group">
        {label && <label>{label}</label>}
        {children}
      </div>
    );
  }
  // Otherwise render a select dropdown
  return (
    <div className="filter-group">
      {label && <label>{label}</label>}
      <select value={value} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default FilterGroup;