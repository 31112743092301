import React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

const SchoolMarker = ({ school, icon }) => {
  return (
    <Marker 
      position={[school.Latitude, school.Longitude]} 
      icon={icon}
    >
      <Popup>
        <div>
          <strong>{school.SchoolName}</strong>
          <br />
          {school.Address}, {school.Postcode}
          <br />
          {school.LocalAuthority} - {school.SchoolType}
          <br />
          Category: {school.SchoolCategory}
          <br />
          Pupils: {school.Total}
        </div>
      </Popup>
    </Marker>
  );
};

export default SchoolMarker;