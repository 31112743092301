import React from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from "@mui/material/Slider";
import FilterGroup from "./FilterGroup";
import "./styles.css";

const FilterPanel = ({
  filters,
  setFilters,
  councils,
  schoolTypes,
  schoolCategories,
  farmProduceList,
  schools,
  selectedSchool,
  handleSchoolSelect,
  handleToggleSchools,
  handleToggleFarms
}) => {
  return (
    <div className="filter-content">
      {/* Layer visibility toggles */}
      <FilterGroup label="Layer Visibility">
        <FormControlLabel
          control={
            <Switch
              checked={filters.showSchools}
              onChange={handleToggleSchools}
              color="primary"
            />
          }
          label="Show Schools"
        />
        <FormControlLabel
          control={
            <Switch
              checked={filters.showFarms}
              onChange={handleToggleFarms}
              color="primary"
            />
          }
          label="Show Farms"
        />
      </FilterGroup>
      
      {/* Council filter */}
      <FilterGroup
        label="Council"
        value={filters.council}
        onChange={(e) => setFilters({ ...filters, council: e.target.value })}
        options={[
          { value: "all", label: "All Councils" },
          ...councils.map(council => ({ value: council, label: council }))
        ]}
      />
      
      {/* School type filter */}
      <FilterGroup
        label="School Type"
        value={filters.schoolType}
        onChange={(e) => setFilters({ ...filters, schoolType: e.target.value })}
        options={[
          { value: "all", label: "All School Types" },
          ...schoolTypes.map(type => ({ value: type, label: type }))
        ]}
      />
      
      {/* School category filter */}
      <FilterGroup
        label="School Category"
        value={filters.schoolCategory}
        onChange={(e) => setFilters({ ...filters, schoolCategory: e.target.value })}
        options={[
          { value: "all", label: "All Categories" },
          ...schoolCategories.map(category => ({ value: category, label: category }))
        ]}
      />
      
      {/* Farm produce filter */}
      <FilterGroup
        label="Farm Produce"
        value={filters.farmProduce}
        onChange={(e) => setFilters({ ...filters, farmProduce: e.target.value })}
        options={[
          { value: "all", label: "All Farm Produce" },
          ...farmProduceList.map(produce => ({ value: produce, label: produce }))
        ]}
      />
      
      {/* School selection */}
      <FilterGroup
        label="Select Specific School"
        value={selectedSchool ? (selectedSchool.id || selectedSchool.SchoolName) : "all"}
        onChange={handleSchoolSelect}
        options={[
          { value: "all", label: "All Schools" },
          ...schools.map(school => ({ 
            value: school.id || school.SchoolName, 
            label: school.SchoolName 
          }))
        ]}
      />

      {/* Distance slider */}
      {selectedSchool && (
        <FilterGroup label={`Farms within radius of ${selectedSchool.SchoolName}`}>
          <div className="school-focus-info">
            Viewing: Selected school and farms within radius
          </div>
          <Slider 
            value={filters.distance} 
            min={0} max={100} step={5} 
            onChange={(e, value) => setFilters({ ...filters, distance: value })} 
            valueLabelDisplay="auto" 
          />
          <div className="slider-value">
            Current radius: {filters.distance} km
          </div>
        </FilterGroup>
      )}
    </div>
  );
};

export default FilterPanel;