/**
 * Functions for validating coordinates within UK boundaries
 */

// Define UK coordinate boundaries
// These are approximate bounding box coordinates for the United Kingdom
const UK_BOUNDS = {
    north: 60.86, // Northern Scotland
    south: 49.86, // Southern England
    west: -8.65,  // Western Ireland/Atlantic
    east: 1.76    // Eastern England/North Sea
  };
  
  // Function to check if coordinates are within UK boundaries
  export const isWithinUK = (latitude, longitude) => {
    // Check if coordinates are numeric
    if (isNaN(latitude) || isNaN(longitude)) {
      return false;
    }
    
    // Check if coordinates are within UK bounding box
    return (
      latitude >= UK_BOUNDS.south &&
      latitude <= UK_BOUNDS.north &&
      longitude >= UK_BOUNDS.west &&
      longitude <= UK_BOUNDS.east
    );
  };